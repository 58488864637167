@import "../../../../styles/foundations/mixins.scss";

.companyPolicies {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #1b1e24;

  .companyPolicies__wrapper {
    width: 100%;
    width: 1550px;
    height: 100%;
    padding: 0 80px;

    @media screen and (max-width: 1550px) {
      width: 100%;
    }

    @include tablet {
      padding: 0 40px;
      transition: all 0.3s ease-in-out;
    }

    @include portraitTablet {
      padding: 0 15px;
      transition: all 0.3s ease-in-out;
    }

    .companyPolicies__content {
      width: 100%;
      display: flex;
      flex-direction: column;

      padding: 80px 0;
      transition: all 0.3s ease-in-out;

      @include portraitTablet {
        padding: 40px 0;
        transition: all 0.3s ease-in-out;
      }

      .white {
        color: white !important;
      }

      .cp__buttons {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

        @include portraitTablet {
          grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
          transition: all 0.3s ease-in-out;
        }

        .cp__button {
          display: flex;
          flex-direction: column;
          align-items: center;

          padding: 20px;
          cursor: pointer;
          user-select: none;

          .cp__buttonCountText {
            font-size: 35px;
            font-weight: 700;
            color: red;

            @include portraitTablet {
              font-size: 16px;
              transition: all 0.3s ease-in-out;
            }
          }

          .cp__buttonText {
            font-size: 25px;
            letter-spacing: 1px;
            font-weight: 600;
            text-align: center;
            color: white;

            @include portraitTablet {
              font-size: 14px;
              transition: all 0.3s ease-in-out;
            }
          }
        }

        .cp__buttonActive {
          background-color: #2f343f;
        }
      }

      .cp__content {
        width: 100%;
        display: flex;
        flex-direction: column;

        gap: 20px;
        padding: 30px 50px;
        background-color: white;

        @include portraitTablet {
          padding: 30px 20px;
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }
}
