@import "../../../styles/foundations/mixins.scss";

.servicePage {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 100px;

  .services {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    padding-bottom: 80px;

    .servicesContent {
      width: 1550px;
      height: 100%;
      padding: 0 80px;

      @media screen and (max-width: 1550px) {
        width: 100%;
      }

      @include tablet {
        padding: 0 40px;
        transition: all 0.3s ease-in-out;
      }

      @include portraitTablet {
        padding: 0 15px;
        transition: all 0.3s ease-in-out;
      }

      .service__container {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
        gap: 20px;

        @include miniPortraitTablet {
          grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        }

        .service__containerLink {
          text-decoration: none;
        }
      }
    }
  }
}
