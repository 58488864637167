@import "../../../styles/foundations/mixins.scss";

.about__loading {
  height: 100%;
  width: 100%;

  .al__id {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 0.7fr;
    align-items: center;
    gap: 40px;

    @include extraLargeTablet {
      grid-template-columns: 1fr;
      gap: 30px;
    }

    .al__idl {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      gap: 60px;

      .al__header {
        width: 60%;
        height: 45px;
      }

      .aldesc {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .al__desc {
          width: 80%;
          height: 30px;
        }

        :nth-child(2) {
          width: 85%;
          height: 25px;
        }
        :nth-child(3) {
          width: 75%;
          height: 25px;
        }
        :nth-child(4) {
          width: 90%;
          height: 25px;
        }
      }

      .al__btn {
        height: 45px;
        width: 140px;
      }
    }

    .al__idr {
      width: 100%;
      display: flex;
      justify-content: center;

      .al__img {
        min-height: 500px;
        height: auto;
        width: 80%;
        object-fit: cover;

        @include extraLargeTablet {
          width: 100%;
        }
      }
    }
  }
}
