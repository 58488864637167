@import "../../../styles/foundations/mixins.scss";

.project__card {
  position: relative;
  width: 100%;
  height: 450px;
  // background: #232323;
  background: #ffffff;
  // border: 1px solid #e0e0e0b4;
  overflow: hidden;
  cursor: pointer;
  user-select: none;

  &:hover {
    .project__cardImgBx {
      top: 1%;
      transform: translateY(-25%);
    }

    .project__cardContentBx {
      height: 210px;
      padding-top: 10px;

      .size {
        opacity: 1;
        visibility: visible;
        // transition-delay: 0.5s;
        transition-delay: 0.2s;
      }

      .color {
        opacity: 1;
        visibility: visible;
        transition-delay: 0.6s;
      }

      // a {
      //   opacity: 1;
      //   transform: translateY(0px);
      //   transition-delay: 0.7s;
      // }

      .projectCardBtn {
        opacity: 1;
        transform: translateY(0px);
        // transition-delay: 0.7s;
        transition-delay: 0.4s;
      }
    }
  }

  .project__cardImgBx {
    position: absolute;
    top: 49%;
    transform: translateY(-50%);

    width: 100%;
    height: 100%;
    transition: 0.5s;
    z-index: 1000;

    @include extraLargeTablet {
      top: 0%;
      transform: translateY(-25%);
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 75%;
      background-color: #31313186;
    }
  }

  .project__cardContentBx {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 90px;

    text-align: center;
    // transition: 1s;
    transition: 0.6s;
    z-index: 90;

    @include extraLargeTablet {
      height: 210px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }

    h2 {
      position: relative;
      font-size: 22px;
      font-weight: 700;
      letter-spacing: 1px;
      color: #1f1c1c;
    }

    .size,
    .color {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 5px;

      padding: 15px 20px;
      opacity: 0;
      visibility: hidden;
      // transition: 0.5s;
      transition: 0.3s;

      @include extraLargeTablet {
        opacity: 1;
        visibility: visible;
      }

      h3 {
        color: #161616;
        font-weight: 300;
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 2px;
      }

      span {
        padding: 1px 5px;
        background-color: rgba(219, 219, 219, 0.308);
        border-radius: 2px;
        border: 0.7px solid #aaaaaa;
        color: rgb(90, 90, 90);
        font-size: 16px;
        font-weight: 600;
        // text-transform: uppercase;
        letter-spacing: 2px;
      }
    }

    .projectCardBtn {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      margin-top: 10px;

      opacity: 0;
      transform: translateY(50px);
      // transition: 0.5s;
      transition: 0.3s;

      @include extraLargeTablet {
        opacity: 1;
        transform: translateY(0px);
      }
    }

    // a {
    //   display: inline-block;
    //   padding: 10px 20px;
    //   background: #fff;
    //   border: 2px solid #000;
    //   margin-top: 10px;
    //   text-decoration: none;
    //   font-size: 20px;
    //   font-weight: 600;
    //   color: #111;
    //   opacity: 0;
    //   transform: translateY(50px);
    //   transition: 0.5s;

    //   @include extraLargeTablet {
    //     opacity: 1;
    //     transform: translateY(0px);
    //   }
    // }
  }
}
