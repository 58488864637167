@import "../../../styles/foundations/mixins.scss";

.subHero {
  position: relative;
  width: 100%;
  height: 500px;

  @include mediumTablet {
    height: 400px;
    transition: all 0.3s ease-in-out;
  }

  @include portraitTablet {
    height: 300px;
    transition: all 0.3s ease-in-out;
  }

  .subHero__bg {
    width: 100%;
    height: 100%;
    background-color: rgb(24, 28, 37);
  }

  .subHero__banner {
    width: 100%;
    height: 100%;
    background-color: #1b1718;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: brightness(0.4);
    }
  }

  .subHerotitleDesc {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -35%);

    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

    .subHerotitle {
      font-size: 70px;
      text-align: center;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 2px;
      color: white;
      transition: all 0.3s ease-in-out;

      @include tablet {
        font-size: 50px;
        transition: all 0.3s ease-in-out;
      }

      @include portraitTablet {
        font-size: 40px;
        transition: all 0.3s ease-in-out;
      }
    }

    .subHeroDesc {
      font-size: 30px;
      text-align: center;
      color: white;
      transition: all 0.3s ease-in-out;

      @include tablet {
        font-size: 18px;
        transition: all 0.3s ease-in-out;
      }

      @include portraitTablet {
        font-size: 16px;
        transition: all 0.3s ease-in-out;
      }
    }

    .subHeroProject {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;

      .subHeroProject__name {
        font-size: 40px;
        text-align: center;
        color: white;
        transition: all 0.3s ease-in-out;

        @include tablet {
          font-size: 18px;
          transition: all 0.3s ease-in-out;
        }

        @include portraitTablet {
          font-size: 16px;
          transition: all 0.3s ease-in-out;
        }
      }

      .subHeroProject__location {
        font-size: 32px;
        font-weight: 600;
        text-align: center;
        color: white;
        transition: all 0.3s ease-in-out;

        @include tablet {
          font-size: 16px;
          transition: all 0.3s ease-in-out;
        }

        @include portraitTablet {
          font-size: 14px;
          transition: all 0.3s ease-in-out;
        }
      }

      .subHeroProject__tags {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 15px;

        span {
          padding: 3px 10px;
          border: 1px solid white;
          font-size: 20px;
          color: white;
          transition: all 0.3s ease-in-out;

          @include tablet {
            font-size: 16px;
            transition: all 0.3s ease-in-out;
          }

          @include portraitTablet {
            font-size: 14px;
            transition: all 0.3s ease-in-out;
          }
        }
      }
    }
  }
}
