@import "../../../styles/foundations/mixins.scss";

.careerPage {
  display: flex;
  flex-direction: column;

  .careerPage__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 60px;

    @include tablet {
      margin-top: 0;
    }

    @include miniPortraitTablet {
      gap: 40px;
    }

    .careerPage__headerDesc {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .careerPage__header {
        display: flex;
        flex-direction: column;

        .careerPage__headerText {
          font-weight: 700;
          font-size: 32px;
          line-height: 42px;
          // color: var(--white);

          @include tablet {
            font-size: 28px;
            line-height: 38px;
          }

          @include largeMobile {
            font-size: 20px;
            line-height: 30px;
          }
        }
      }

      .careerPage__descText {
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        // color: var(--white);

        @include tablet {
          font-size: 16px;
          line-height: 24px;
        }

        @include largeMobile {
          font-size: 14px;
          line-height: 22px;
        }
      }
    }

    .career__container {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .career__header {
        display: flex;
        flex-direction: column;
        gap: 5px;

        .career__headerText {
          font-weight: 700;
          font-size: 24px;
          line-height: 34px;
          // color: var(--white);

          @include tablet {
            font-size: 20px;
            line-height: 30px;
          }

          @include largeMobile {
            font-size: 18px;
            line-height: 28px;
          }
        }
      }

      .career__rowContainer {
        width: 100%;
        display: flex;
        flex-direction: column;

        gap: 10px;
        padding: 10px 0 0 10px;
        box-sizing: border-box;

        .career__row {
          width: 100%;
          display: flex;
          justify-content: space-between;

          padding: 16px 0;
          box-sizing: border-box;
          border-bottom: 1px solid #504b4b;
          text-decoration: none;
          color: var(--black);
          cursor: pointer;

          @include tablet {
            gap: 30px;
          }

          .career__rowLeft {
            span {
              font-size: 18px;
              font-weight: 500;
              color: var(--black);

              @include tablet {
                font-size: 16px;
              }
            }
          }

          .career__rowRight {
            display: flex;
            align-items: center;
            gap: 10px;

            span {
              font-size: 18px;
              font-weight: 500;
              // line-height: 48px;
              // color: var(--white);

              @include tablet {
                font-size: 16px;
                // line-height: 38px;
              }
            }

            i {
              font-size: 15px;
              // color: var(--white);

              @include tablet {
                font-size: 12px;
                // line-height: 38px;
              }
            }
          }
        }
      }
    }
  }
}
