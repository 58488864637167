@import "../../../styles/foundations/mixins.scss";

.projectListPage {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 100px;

  .projectListPage__container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    padding-bottom: 80px;

    .projectListPage__content {
      width: 1550px;
      height: 100%;
      padding: 0 80px;

      @media screen and (max-width: 1550px) {
        width: 100%;
      }

      @include tablet {
        padding: 0 40px;
        transition: all 0.3s ease-in-out;
      }

      @include portraitTablet {
        padding: 0 15px;
        transition: all 0.3s ease-in-out;
      }

      .pgp__projects {
        width: 100%;
        min-height: 500px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
        gap: 30px;

        @include tablet {
          grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
          gap: 20px;
        }

        @include portraitTablet {
          grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
          gap: 10px;
        }
      }
    }
  }
}
