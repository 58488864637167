@import "../../../styles/foundations/mixins.scss";

.navbar {
  position: fixed;
  top: 0;

  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--backgroundColor);
  // background-color: transparent;
  box-shadow: 0 0 10px rgba(97, 97, 97, 0.4);
  z-index: var(--navZindex);
  transition: all 0.3s ease-in-out;

  @include portraitTablet {
    height: 100px;
  }

  .navbarContainer {
    width: 1550px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 80px;
    transition: all 0.3s ease-in-out;

    @media screen and (max-width: 1550px) {
      width: 100%;
    }

    @include mediumDesktop {
      padding: 0 20px;
      transition: all 0.3s ease-in-out;
    }

    .navbar__left {
      display: flex;
      align-items: center;
      gap: 10px;

      @include extraLargeTablet {
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        gap: 5px;
      }

      .logo {
        width: 100%;
        height: 100%;
        display: flex;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .navNaimatedLogo {
        display: flex;
        gap: 20px;

        .navLogoIcons {
          display: flex;
          flex-direction: column;
          cursor: pointer;

          .nlBar1 {
            height: 29px;
            width: 15px;
            background-color: red;
            clip-path: polygon(0 12%, 35% 1%, 100% 57%, 100% 100%);
            transform: rotate(-10deg);
          }

          .nlBar2 {
            height: 29px;
            width: 20px;
            background-color: red;
            clip-path: polygon(0 59%, 0 23%, 100% 66%, 100% 100%);
          }
        }
      }
    }

    .navbar__menu {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;

      @include semiLargeDesktop {
        gap: 20px;
      }

      .navbar__menuItem {
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 0 10px;
        text-decoration: none;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        animation: menuItemAnimation 1s ease-in-out;

        // color: white;

        @include tablet {
          display: none;
        }

        &:hover {
          transition: all 0.3s ease-in-out;

          .navbar__menuItemText {
            color: var(--brandColor);
          }

          .navbar__menuItemIcon {
            color: var(--brandColor);
          }
        }

        .navbar__menuItemText {
          font-size: 18px;
          font-weight: 600;
          letter-spacing: 2px;
          text-transform: uppercase;
          transition: all 0.3s ease-in-out;
        }

        .navbar__menuItemIcon {
          font-size: 20px;
          color: var(--textBlack);
        }
      }

      .active__nav {
        .navbar__menuItemText {
          color: var(--brandColor) !important;
        }

        .navbar__menuItemIcon {
          color: var(--brandColor) !important;
        }
      }

      .navMobile__menuIcon {
        display: none;

        @include tablet {
          display: block;
        }

        .navbar__leftIcon {
          svg {
            margin-top: 5px;
            font-size: 22px;
            color: var(--black);
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &:hover {
              color: var(--brandColor);
              transition: all 0.3s ease-in-out;
            }
          }
        }

        .navMobile__menu {
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
          height: 100vh;
          display: flex;
          justify-content: flex-end;
          background-color: rgba(0, 0, 0, 0.2);
          backdrop-filter: blur(2px);
          z-index: 100;
          transition: all 0.5s ease-in-out;
          transform: translateX(100%);

          &.active {
            transform: translateX(0);
          }

          .navMobile__menuContainer {
            width: 75%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 10px 0;

            background-color: var(--backgroundColor);
            box-shadow: var(--boxShadow);

            .navMobile__menuCloseIcon {
              width: 100%;
              display: flex;
              // align-items: flex-end;
              justify-content: flex-end;

              svg {
                padding-right: 20px;
                font-size: 50px;
                color: var(--brandColor);
                cursor: pointer;
              }
            }

            .navMobile__menuLogo {
              width: 150px;
              height: 30px;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .companyMoto {
              margin: 20px 0;
              width: 80%;
              text-align: center;
              font-size: 18px;
              font-weight: 500;
              letter-spacing: 1.5px;
              color: var(--textBlack);

              @include miniPortraitTablet {
                font-size: 15px;
              }
            }

            .navMobile__menuItem {
              width: 100%;
              height: 50px;
              display: flex;
              align-items: center;
              text-decoration: none;
              cursor: pointer;
              transition: all 0.3s ease-in-out;
              border-bottom: 0.0001px solid rgba(24, 24, 24, 0.212);

              &:hover {
                transition: all 0.3s ease-in-out;

                .navMobile__menuItemText {
                  color: var(--brandColor);
                }

                .navMobile__menuItemIcon {
                  color: var(--brandColor);
                }
              }

              .navMobile__menuItemText {
                font-size: 13px;
                font-weight: 500;
                letter-spacing: 1.5px;
                color: var(--black);
                text-transform: uppercase;
                transition: all 0.3s ease-in-out;
                text-decoration: none;
                padding-left: 15px;
              }

              svg {
                font-size: 20px;
                color: var(--white);
              }
            }

            .navMobile__menuItem:first-child {
              border-top: 10px solid var(--brandColor) !important;
            }

            .activeMobile__nav {
              border-left: 2px solid var(--brandColor);

              .navMobile__menuItemText {
                color: var(--brandColor);
              }

              .navMobile__menuItemIcon {
                color: var(--brandColor);
              }
            }
          }
        }
      }
    }

    .navbar__right {
      display: flex;
      align-items: center;
      gap: 20px;

      @include miniPortraitTablet {
        gap: 10px;
      }

      .navbar__rightIcon {
        display: flex;
        align-items: center;

        font-size: 25px;
        color: var(--black);
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        animation: rightMenuAnimation 1s ease-in-out;

        &:hover {
          color: var(--brandColor);
          transition: all 0.3s ease-in-out;
        }

        @include miniPortraitTablet {
          font-size: 20px;
        }
      }

      .navbar__aboutContainer {
        .navbar__aboutMenu {
          position: absolute;
          top: 0;
          right: 0;

          width: min(100%, 400px);
          height: 0;
          display: flex;
          justify-content: flex-end;
          z-index: 100;
          transition: all 0.5s ease-in-out;
          transform: translateX(100%);
          height: 100vh;

          &.activeAbout {
            transform: translateX(0);
          }

          @include tablet {
            width: 80%;
          }

          .navbar__aboutMenuContainer {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 6%;
            padding: 50px;
            background-color: var(--backgroundColor);
            box-shadow: var(--boxShadow);

            .navbar__aboutMenuCloseIcon {
              width: 100%;
              display: flex;
              justify-content: flex-end;

              svg {
                font-size: 35px;
                color: var(--brandColor);
                cursor: pointer;

                @include tablet {
                  font-size: 35px;
                }
              }
            }

            .navbarAbout__logo {
              width: 100%;
              height: 50px;
              display: flex;
              justify-content: center;

              img {
                min-width: 140px;
                height: 100%;
              }
            }

            .navbarAbout__text {
              width: 100%;
              text-align: center;

              span {
                font-size: 18px;
                font-weight: 500;
                letter-spacing: 1.5px;
                color: var(--textBlack);
                transition: all 0.3s ease-in-out;
                text-align: center;

                @include tablet {
                  font-size: 16px;
                }
              }
            }

            .navbarAbout__contact {
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 15px;

              h3 {
                font-size: 25px;
                font-weight: 500;
                letter-spacing: 1.5px;
                color: var(--textBlack);
                transition: all 0.3s ease-in-out;
              }

              span {
                font-size: 18px;
                font-weight: 500;
                letter-spacing: 1.5px;
                color: var(--textBlack);
                transition: all 0.3s ease-in-out;

                @include tablet {
                  font-size: 16px;
                }
              }
            }

            .navbarAbout__address {
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 15px;

              h3 {
                font-size: 25px;
                font-weight: 500;
                letter-spacing: 1.5px;
                color: var(--textBlack);
                transition: all 0.3s ease-in-out;
              }

              span {
                max-width: 80%;
                font-size: 18px;
                font-weight: 500;
                letter-spacing: 1.5px;
                color: var(--textBlack);
                text-align: center;
                transition: all 0.3s ease-in-out;

                @include tablet {
                  max-width: 100%;
                  font-size: 16px;
                }
              }
            }

            .navbarAbout__socials {
              width: 100%;
              display: flex;
              justify-content: center;
              gap: 5px;

              .navbarAbout__social {
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                color: var(--textBlack);
                transition: all 0.3s ease-in-out;
                padding: 15px;
                border: 1px solid var(--textBlack);
                cursor: pointer;

                &:hover {
                  color: var(--brandColor);
                  border: 1px solid var(--brandColor);
                  transition: all 0.3s ease-in-out;
                }

                i {
                  font-size: 15px;
                }
              }

              // i {
              //   width: 35px;
              //   height: 35px;
              //   display: flex;
              //   align-items: center;
              //   justify-content: center;
              //   border-radius: 50%;
              //   font-size: 20px;
              //   color: var(--textBlack);
              //   transition: all 0.3s ease-in-out;
              //   padding: 10px;
              //   border: 1px solid var(--textBlack);
              //   cursor: pointer;

              //   &:hover {
              //     color: var(--brandColor);
              //     border: 1px solid var(--brandColor);
              //     transition: all 0.3s ease-in-out;
              //   }
              // }
            }
          }
        }
      }

      .navbar__rightLine {
        width: 1px;
        height: 20px;
        background-color: var(--black);
        animation: rightMenuAnimation 1s ease-in-out;
      }

      .navbar__search {
        position: absolute;
        top: 120px;
        right: 0;

        width: max-content;
        height: max-content;
        display: flex;

        .navbar__searchContainer {
          width: 300px;
          height: 60px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          background-color: var(--black);
          padding: 15px 5px;
          animation: searchAnimation 0.5s ease-in-out;
          @include miniPortraitTablet {
            height: 40px;
            width: min(100%, 200px);
          }

          input {
            width: 100%;
            border: none;
            outline: none;
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 1.5px;
            color: var(--white);
            transition: all 0.3s ease-in-out;
            background-color: transparent;

            @include miniPortraitTablet {
              font-size: 16px;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}

.nbg {
  background-color: white;
}

.nbg-remove {
  background-color: transparent;
}

.active__icon {
  color: var(--brandColor) !important;
}

@keyframes searchAnimation {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes menuItemAnimation {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  50% {
    opacity: 0.5;
    transform: translateY(10%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes rightMenuAnimation {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes movePath1 {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
