@import "../../../styles/foundations/mixins.scss";

.sectionHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .sectionHeader__text {
    font-size: 35px;
    font-weight: 700;
    letter-spacing: 1px;

    @include portraitTablet {
      font-size: 20px;
    }
  }

  .sectionHeader__link {
    text-decoration: none;

    span {
      font-size: 20px;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      font-weight: 700;
      color: rgb(27, 39, 48);
      transition: all 0.3s ease-in-out;

      &:hover {
        color: red;
      }

      @include portraitTablet {
        font-size: 16px;
      }
    }
  }
}
