@import "../../styles/foundations/mixins.scss";

.skeleton {
  background-color: #e2e5e7;
  background-image: linear-gradient(
    90deg,
    rgba(#fff, 0),
    rgba(#fff, 0.3),
    rgba(#fff, 0)
  );
  background-size: 70px 100%;
  background-repeat: no-repeat;
  background-position: left -70px top 0;
  animation: shine 2s ease infinite;
}

.skeletonType1 {
  background-color: #999999;
  background-image: linear-gradient(
    90deg,
    rgba(#fff, 0),
    rgba(#fff, 0.3),
    rgba(#fff, 0)
  );
  background-size: 70px 100%;
  background-repeat: no-repeat;
  background-position: left -70px top 0;
  animation: shine 2s ease infinite;
}
@keyframes shine {
  to {
    background-position: right -70px top 0;
  }
}

.skeletonBtn {
  width: 150px;
  height: 25px;

  @include mediumTablet {
    width: 120px;
    height: 20px;
  }

  @include portraitTablet {
    width: 100px;
    height: 15px;
  }
}
