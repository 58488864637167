@import "../foundations/mixins.scss";

.a2__container__btns {
  position: sticky;
  top: 99px;

  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 40px;
  border-bottom: 1.5px solid rgba(209, 209, 209, 0.541);
  background-color: white;
  z-index: 10000;

  padding: 0 5%;

  .a2B {
    padding: 20px 15px;
    text-decoration: none;
    list-style: none;
    font-size: 20px;
    font-weight: 600;
    color: rgb(54, 54, 54);
    border-bottom: 2px solid transparent;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    @include portraitTablet {
      padding: 5px 10px;
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      color: var(--brandColor);
      border-bottom: 2px solid var(--brandColor);
      transition: all 0.3s ease-in-out;
    }

    @include portraitTablet {
      font-size: 14px;
      transition: all 0.3s ease-in-out;
    }
  }

  .active {
    color: var(--brandColor);
    border-bottom: 2px solid var(--brandColor);
    transition: all 0.3s ease-in-out;
  }
}
