@import "../../../../styles/foundations/mixins.scss";

.projectCardSkeleton {
  width: 100%;
  height: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  .projectCardSkeleton__img {
    width: 100%;
    height: 70%;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .skeleton__placeholder {
    background-color: #a5a5a5;
    animation: skeleton-animation 1s infinite ease-in-out;
    animation: animation 2.5s infinite;
  }

  .pcs__header {
    height: 30px;
    width: 80%;
  }
}

@keyframes animation {
  0% {
    opacity: 0.2;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 0.2;
  }
}
