@import "../../../../styles/foundations/mixins.scss";

// type 1
// @keyframes movePath1 {
//   0% {
//     transform: translateX(-35px) translateY(-5px);
//     opacity: 0;
//   }
//   100% {
//     transform: translateX(0) translateY(0);
//     opacity: 1;
//   }
// }
// #animatedPath1 {
//   animation: movePath1 1.5s ease;
// }

// @keyframes movePath2 {
//   0% {
//     transform: translateX(20px) translateY(22px);
//     opacity: 0;
//   }
//   100% {
//     transform: translateX(0) translateY(0);
//     opacity: 1;
//   }
// }
// #animatedPath2 {
//   animation: movePath2 1.5s ease;
// }

// @keyframes movePath3 {
//   0% {
//     transform: translateX(-35px) translateY(-5px);
//     opacity: 0;
//   }
//   100% {
//     transform: translateX(0) translateY(0);
//     opacity: 1;
//   }
// }
// #animatedPath3 {
//   animation: movePath3 1.5s ease;
// }

// @keyframes path_S {
//   0% {
//     transform: translateX(-16px);
//     opacity: 0;
//   }
//   100% {
//     transform: translateX(0);
//     opacity: 1;
//   }
// }
// #path_S {
//   animation: path_S 1.5s ease;
//   animation-delay: 0s;
// }

// @keyframes path_E {
//   0% {
//     transform: translateX(-35px);
//     opacity: 0;
//   }
//   100% {
//     transform: translateX(0);
//     opacity: 1;
//   }
// }
// #path_E {
//   animation: path_E 1.5s ease;
//   animation-delay: 0.1s;
// }

// @keyframes path_M {
//   0% {
//     transform: translateX(-50px);
//     opacity: 0;
//   }
//   100% {
//     transform: translateX(0);
//     opacity: 1;
//   }
// }
// #path_M {
//   animation: path_M 1.5s ease;
//   animation-delay: 0.2s;
// }

// @keyframes path_C {
//   0% {
//     transform: translateX(-80px);
//     opacity: 0;
//   }
//   100% {
//     transform: translateX(0);
//     opacity: 1;
//   }
// }
// #path_C {
//   animation: path_C 1.5s ease;
//   animation-delay: 0.3s;
//   //   transform: translateX(-80px);
// }

// @keyframes path_O {
//   0% {
//     transform: translateX(-100px);
//     opacity: 0;
//   }
//   100% {
//     transform: translateX(0);
//     opacity: 1;
//   }
// }
// #path_O {
//   animation: path_O 1.5s ease;
//   animation-delay: 0.4s;
//   //   transform: translateX(-100px);
// }

// @keyframes path_R {
//   0% {
//     transform: translateX(-120px);
//     opacity: 0;
//   }
//   100% {
//     transform: translateX(0);
//     opacity: 1;
//   }
// }
// #path_R {
//   animation: path_R 1.5s ease;
//   animation-delay: 0.5s;
//   //   transform: translateX(-120px);
// }

// @keyframes path_P {
//   0% {
//     transform: translateX(-140px);
//     opacity: 0;
//   }
//   100% {
//     transform: translateX(0);
//     opacity: 1;
//   }
// }
// #path_P {
//   animation: path_P 1.5s ease;
//   animation-delay: 0.6s;
//   //   transform: translateX(-140px);
// }

// type 2
@keyframes movePath1 {
  0%,
  50% {
    transform: translateX(0) translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-35px) translateY(-5px);
    opacity: 0;
  }
}
#animatedPath1 {
  animation: movePath1 4s ease alternate infinite;
  animation-delay: 5s;
}

@keyframes movePath2 {
  0%,
  50% {
    transform: translateX(0) translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateX(20px) translateY(22px);
    opacity: 0;
  }
}
#animatedPath2 {
  animation: movePath2 4s ease alternate infinite;
  animation-delay: 5s;
}

@keyframes movePath3 {
  0%,
  50% {
    transform: translateX(0) translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-35px) translateY(-5px);
    opacity: 0;
  }
}
#animatedPath3 {
  animation: movePath3 4s ease alternate infinite;
  animation-delay: 5s;
}

@keyframes path_S {
  0%,
  50% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-16px);
    opacity: 0;
  }
}
#path_S {
  animation: path_S 4s ease alternate infinite;
  animation-delay: 5s;
}

@keyframes path_E {
  0%,
  50% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-35px);
    opacity: 0;
  }
}
#path_E {
  animation: path_E 4s ease alternate infinite;
  animation-delay: 5.2s;
}

@keyframes path_M {
  0%,
  50% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-50px);
    opacity: 0;
  }
}
#path_M {
  animation: path_M 4s ease alternate infinite;
  animation-delay: 5.3s;
}

@keyframes path_C {
  0%,
  50% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-80px);
    opacity: 0;
  }
}
#path_C {
  animation: path_C 4s ease alternate infinite;
  animation-delay: 5.4s;
}

@keyframes path_O {
  0%,
  50% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-100px);
    opacity: 0;
  }
}
#path_O {
  animation: path_O 4s ease alternate infinite;
  animation-delay: 5.5s;
}

@keyframes path_R {
  0%,
  50% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-120px);
    opacity: 0;
  }
}
#path_R {
  animation: path_R 4s ease alternate infinite;
  animation-delay: 5.6s;
}

@keyframes path_P {
  0%,
  50% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-140px);
    opacity: 0;
  }
}
#path_P {
  animation: path_P 4s ease alternate infinite;
  animation-delay: 5.7s;
}
