@import "../../../styles/foundations/mixins.scss";

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper__child {
    width: 1550px;
    height: 100%;
    padding: 0 80px;

    @media screen and (max-width: 1550px) {
      width: 100%;
    }

    @include tablet {
      padding: 0 40px;
      transition: all 0.3s ease-in-out;
    }

    @include portraitTablet {
      padding: 0 15px;
      transition: all 0.3s ease-in-out;
    }
  }
}
