@import "../../styles/foundations/mixins.scss";

$primary: #fa1e1e;
$secondary: #eb781b;
$white: #fff;
$gray: #c0c0c0;
// $gray: #9b9b9b;

.input__group {
  position: relative;
  padding: 15px 0 0;
  width: 100%;

  .input__field {
    font-family: inherit;
    width: 100%;
    border: none;
    outline: none;
    border-bottom: 2px solid $gray;
    font-size: 20px;
    font-weight: 600;
    color: black;
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;

    &::placeholder {
      color: transparent;
    }

    &:placeholder-shown ~ .input__label {
      font-size: 18px;
      font-weight: 500;
      cursor: text;
      top: 20px;
    }

    &:focus {
      ~ .input__label {
        position: absolute;
        top: 0;
        display: block;
        transition: 0.2s;
        font-size: 18px;
        color: $primary;
        font-weight: 600;
      }

      padding-bottom: 6px;
      font-weight: 600;
      border-width: 2px;
      border-image: linear-gradient(to right, $primary, $secondary);
      border-image-slice: 1;
    }
  }

  .input__label {
    position: absolute;
    top: 0;
    display: block;
    font-size: 18px;
    color: $gray;
    transition: 0.2s;
  }

  .input__textArea {
    font-family: inherit;
    width: 100%;
    border: none;
    outline: none;
    border-bottom: 2px solid $gray;
    font-size: 20px;
    font-weight: 600;
    color: black;
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;
    resize: none;

    &::placeholder {
      color: transparent;
    }

    &:placeholder-shown ~ .input__label {
      font-size: 18px;
      font-weight: 500;
      cursor: text;
      top: 20px;
    }

    &:focus {
      ~ .input__label {
        position: absolute;
        top: 0;
        display: block;
        transition: 0.2s;
        font-size: 18px;
        color: $primary;
        font-weight: 600;
      }

      padding-bottom: 6px;
      font-weight: 600;
      border-width: 3px;
      border-image: linear-gradient(to right, $primary, $secondary);
      border-image-slice: 1;
    }
  }
}

/* reset input */
.input__field {
  &:required,
  &:invalid {
    box-shadow: none;
  }
}

.messageText {
  font-size: 20px;
  font-weight: 600;

  &.error {
    color: red;
  }

  &.success {
    color: green;
  }

  &.warning {
    color: orange;
  }

  &.info {
    color: blue;
  }
}
