@import "../../../styles/foundations/mixins.scss";

.appLayout {
  height: 100%;
  background-color: var(--backgroundColor);
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  padding-top: 100px;

  .appLayout__bgImg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;

    object-fit: cover;
    object-position: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .appBlock {
    min-height: calc(100vh - 120px);
    z-index: 1;
  }
}
