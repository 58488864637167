@import "../../../styles/foundations/mixins.scss";

.careerFormPage {
  display: flex;
  flex-direction: column;

  .careerFormPage__container {
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    gap: 40px;

    .cfp__header {
      border-bottom: 1px solid gray;

      h1 {
        font-size: 2.5rem;
        font-weight: 700;
        text-transform: uppercase;

        @include tablet {
          font-size: 30px;
        }
      }
    }

    .cfp__formContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 40px;

      .cfp__inputContainer {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 4%;
        row-gap: 30px;

        @include tablet {
          grid-template-columns: 1fr;
        }

        .cfp__singleInput {
          width: 100%;
          display: flex;
          flex-direction: column;

          .cfp__label {
            font-size: 20px;
            font-weight: 600;
            color: var(--textWhite);
          }

          .cfp__input {
            width: 100%;
            padding: 8px 0;
            border: none;
            background-color: var(--bgColor);
            color: var(--textWhite);
            border-bottom: 2px solid gray;
            font-size: 18px;
            font-weight: 400;
            transition: all 0.3s ease-in-out;

            &:focus {
              outline: none;
              border-bottom: 2px solid rgb(209, 46, 46);
              box-shadow: 0 0 5px 1px var(--textWhite);
            }
          }
        }

        .cfp__doubleInput {
          width: 100%;
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 5%;

          .cfp__label {
            font-size: 20px;
            font-weight: 600;
            color: var(--textWhite);
          }

          .cfp__input {
            width: 100%;
            padding: 8px 0;
            border: none;
            background-color: var(--bgColor);
            color: var(--textWhite);
            border-bottom: 2px solid gray;
            font-size: 18px;
            font-weight: 400;
            transition: all 0.3s ease-in-out;

            &:focus {
              outline: none;
              border-bottom: 2px solid rgb(209, 46, 46);
              box-shadow: 0 0 5px 1px var(--textWhite);
            }
          }
        }
      }

      .cfp__message {
        width: 100%;
        display: flex;
        flex-direction: column;

        .cfp__label {
          font-size: 20px;
          font-weight: 600;
          color: var(--textWhite);
        }

        .cfp__textarea {
          width: 48%;
          padding: 10px 0;
          border: none;
          background-color: var(--bgColor);
          color: var(--textWhite);
          border-bottom: 2px solid gray;
          font-size: 18px;
          font-weight: 400;
          transition: all 0.3s ease-in-out;
          resize: none;

          &:focus {
            outline: none;
            border-bottom: 3px solid rgb(209, 46, 46);
            box-shadow: 0 0 5px 1px var(--textWhite);
          }
        }
      }

      .cfp__fileInput {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .cfp__label {
          font-size: 20px;
          font-weight: 600;
          color: var(--textWhite);
        }
      }

      .cfp__checks {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .cfp__check {
          display: flex;
          align-items: center;
          gap: 10px;

          input {
            width: 20px;
            height: 20px;
          }

          label {
            font-size: 20px;
            font-weight: 500;
            color: var(--textWhite);
          }
        }
      }
    }
  }
}
