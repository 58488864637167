@import "../../../styles/foundations/mixins.scss";

.contactAdd {
  position: relative;
  width: 100%;
  height: 350px;

  .contactAdd__img {
    width: 100%;
    height: 100%;
    background-color: rgba(21, 32, 53, 0.856);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0.5;
    }
  }

  .contactAdd__content {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    .contactAddText {
      width: 90%;
      display: flex;
      justify-content: center;

      span {
        font-size: 40px;
        font-weight: 700;
        letter-spacing: 1px;
        color: #fff;
        text-align: center;

        @include portraitTablet {
          font-size: 30px;
        }
      }
    }

    .contactAddButton {
      button {
        padding: 18px 30px;
        text-transform: uppercase;
        background-color: #ab2b30;
        border: none;
        font-size: 16px;
        font-weight: 700;
        color: #ffffff;
        cursor: pointer;
        transition: 0.3s ease;

        &:hover {
          transition: 0.3s ease;
          background-color: #d43a3f;
        }

        @include portraitTablet {
          padding: 14px 24px;
          font-size: 14px;
        }
      }
    }
  }
}
