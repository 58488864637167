@import "../../styles/foundations/mixins.scss";

.a2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 100px;

  .a2__container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    .a2__container__content {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 80px;

      @include portraitTablet {
        gap: 40px;
      }

      section {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 40px;
      }
    }
  }
}
