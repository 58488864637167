@import "../../../styles/foundations/mixins.scss";

.sbtn {
  border: none;
  background: #1b1e24ad;
  //   background: transparent;
  color: rgb(202, 202, 202);
  border: 1.5px solid rgb(165, 165, 165);
  text-transform: uppercase;
  position: relative;
  z-index: 1;
  transition: color 400ms;
  cursor: pointer;

  display: flex;
  align-items: center;
  gap: 5px;

  &:hover {
    color: #c7c7c7;

    &::before {
      transform: scaleX(1);
      transform-origin: left;
    }

    span {
      transition: padding 400ms ease-out;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // background: var(--brandColor);
    background: rgb(175, 26, 26);
    z-index: -1;
    transition: transform 400ms ease-out;
    transform: scaleX(0);
    transform-origin: right;
  }

  span {
    padding: 16px 24px;
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: 500;
    transition: padding 400ms ease-out;

    @include portraitTablet {
      padding: 10px 28px;
      font-size: 15px;
    }
  }
}
