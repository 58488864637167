@import "../../../../styles/foundations/mixins.scss";

.skeleton__pdp__info {
  position: absolute;
  top: -170px;
  left: 0;

  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include tablet {
    top: -60px;
    transition: all 0.3s ease-in-out;
  }

  .skeleton__pdp__infoContainer {
    width: 90%;
    min-height: 300px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    gap: 20px;
    padding: 36px;
    background-color: #0c1018f8;
    border-radius: 5px;

    @include tablet {
      width: 92%;
      padding: 15px;
      grid-template-columns: 1fr;
      transition: all 0.3s ease-in-out;
    }

    .skeleton__pdp__ic__left {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .skeleton__pdp__ic__leftItem {
        width: 50%;
        height: 30px;
      }
      :nth-child(1) {
        width: 70%;
      }
      :nth-child(2) {
        width: 40%;
      }
      :nth-child(3) {
        width: 40%;
      }
      :nth-child(4) {
        width: 80%;
      }
      :nth-child(5) {
        width: 30%;
      }
    }

    .skeleton__pdp__ic__right {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .skeleton__pdp__ic__RightItem {
        width: 50%;
        height: 30px;
      }
      :nth-child(1) {
        width: 70%;
      }
      :nth-child(2) {
        width: 40%;
      }
    }
  }
}

.skeleton__pdp__gallery {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;

  @include tablet {
    grid-template-columns: repeat(2, 1fr);
    transition: all 0.3s ease-in-out;
  }

  .skeleton__pdp__galleryImg {
    width: 100%;
    height: 300px;
    display: flex;
    border-radius: 10px;

    @include tablet {
      height: 200px;
    }
  }
}
