@import "../../styles/foundations/mixins.scss";

.cSectionText {
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 1px;

  @include portraitTablet {
    font-size: 25px;
  }
}
