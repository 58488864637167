@import "../../../../styles/foundations/mixins.scss";

.serviceCardSkeleton {
  height: 230px;
  width: 100%;

  position: relative;
  display: flex;
  flex-direction: column;

  .serviceCardSkeleton__image {
    width: 100%;
    height: 100%;
    background-color: rgba(65, 65, 65, 0.466);
  }

  .serviceCardSkeleton__title {
    position: absolute;
    top: 20px;
    left: 20px;

    width: 70%;
    height: 25px;
  }
}
