@import "../../../styles/foundations/mixins.scss";

.serviceCard {
  height: 230px;
  display: flex;
  position: relative;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    transition: all 0.3s ease-in-out;

    .serviceCard__img {
      img {
        transform: scale(1.2);
        transition: all 0.3s ease-in-out;
      }
    }

    .serviceCard__content {
      background: linear-gradient(
        0deg,
        rgba(32, 32, 32, 0.5) 0%,
        rgba(22, 29, 36, 0.651) 100%
      );

      .serviceCard__icon {
        svg {
          width: 40px;
          height: 40px;
          fill: var(--brandColor);
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }

  .serviceCard__img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: var(--imgBackgroundColor);
    // border-radius: 5px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-color: var(--black);
      // border-radius: 5px;
      opacity: var(--imgOpacityBg);
      transition: all 0.3s ease-in-out;
    }
  }

  .serviceCard__content {
    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;

    .serviceCard__text {
      display: flex;
      flex-direction: column;
      gap: 15px;

      .serviceCard__text__header {
        font-family: Rajdhani;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        color: var(--white);
        // text-decoration: underline;
      }

      .serviceCard__text__desc {
        color: #fff;

        font-family: Open Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .serviceCard__icon {
      display: flex;
      align-items: flex-start;

      svg {
        width: 30px;
        height: 30px;
        fill: #fff;
        transition: all 0.3s ease-in-out;
      }
    }
  }
}
