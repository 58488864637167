@import "../../../../styles/foundations/mixins.scss";

.landingProjects {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;

  .landingProjects__headerDesc {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .landingProjects__descText {
      font-size: 20px;
      font-weight: 500;
    }
  }

  .landingProjects__carouselContainer1 {
    width: 100%;
    gap: 20px;

    .lpc__Swiper1 {
      width: 100%;
      padding-bottom: 40px;
      min-height: 500px;

      .swiper-pagination {
        position: absolute;
        bottom: 0;
        fill: var(--white);

        .swiper-pagination-bullet-active {
          width: 12px;
          height: 12px;
          margin: 8px;

          background-color: red !important;
        }

        .swiper-pagination-bullet {
          width: 12px;
          height: 12px;
          margin: 8px;
          border: 6px solid red;
          background-color: transparent;
        }
      }

      .s {
        width: 100%;
        height: 100%;
      }
    }
  }
}
