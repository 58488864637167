@import "../../../styles/foundations/mixins.scss";

.mainHeroUpdated {
  width: 100%;
  height: 650px;
  transition: all 0.3s ease-in-out;

  @include miniPortraitTablet {
    height: 400px;
    transition: all 0.3s ease-in-out;
  }

  .mainHeroUpdated__carousel {
    height: 100%;
    width: 100%;

    padding-bottom: 70px;

    .swiper-pagination {
      position: absolute;
      bottom: 20px;
      fill: var(--white);
      z-index: 1;
      background-color: rgba(0, 0, 0, 0.911);

      // .swiper-pagination-bullet-active {
      //   width: 12px;
      //   height: 12px;
      //   margin: 8px;

      //   background-color: red !important;
      // }

      // .swiper-pagination-bullet {
      //   width: 22px;
      //   height: 22px;
      //   margin: 8px;
      //   border: 8px solid rgba(189, 189, 189, 0.897);
      //   background-color: transparent;
      // }

      // change bullet to custom image
      .swiper-pagination-bullet-active {
        width: 28px;
        height: 28px;
        margin: 8px;

        background-color: transparent !important;

        background-image: url("../../../assets/images/icons/carousel/bullet.png") !important;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }

      .swiper-pagination-bullet {
        width: 28px;
        height: 28px;
        margin: 8px;

        background-color: transparent !important;

        background-image: url("../../../assets/images/icons/carousel/bullet__white.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    .mainHeroUpdated__carouselSlide {
      width: 100%;
      height: 100%;
      cursor: pointer;

      .mainHeroUpdated__carouselItems {
        position: relative;
        width: 100%;
        height: 100%;

        .mainHeroUpdatedCarousel__image {
          width: 100%;
          height: 100%;
          background-color: black;

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            opacity: 0.4;
          }
        }

        .mainHeroUpdatedCarousel__content {
          position: absolute;
          top: 80%;
          left: 20%;
          transform: translate(-20%, -80%);

          width: 100%;
          max-width: 50%;
          display: flex;
          flex-direction: column;
          // align-items: center;
          gap: 10px;

          @include extraLargeTablet {
            max-width: 80%;
          }

          @include portraitTablet {
            top: 70%;
          }

          .mhu__company {
            display: flex;
            align-items: center;
            gap: 5px;

            .mhu__line {
              height: 2px;
              width: 25px;
              background-color: var(--brandColor);
            }

            span {
              font-size: 20px;
              font-weight: 500;
              color: var(--white);
            }
          }

          .mhu__headerText {
            font-size: 65px;
            letter-spacing: 5px;
            text-transform: uppercase;
            font-weight: 700;
            color: var(--white);

            @include extraLargeTablet {
              font-size: 45px;
            }

            @include portraitTablet {
              font-size: 30px;
            }
          }

          .mhu__subText {
            font-size: 40px;
            font-weight: 500;
            color: var(--white);

            @include extraLargeTablet {
              font-size: 30px;
            }

            @include portraitTablet {
              font-size: 20px;
            }
          }

          button {
            width: 200px;
            height: 40px;
            background-color: var(--brandColor);
            color: var(--white);
            border: none;
            border-radius: 5px;
            font-size: 20px;
            font-weight: 500;
            cursor: pointer;
            // animation: buttonAnimation 1.5s ease-in-out 1s;
          }
        }

        .mhuc__animated {
          .mhu__company {
            animation: cNameAnimation 1.5s ease-in-out;
          }

          .mhu__headerText {
            animation: headerAnimation 1.5s ease-in-out;
          }

          .mhu__subText {
            animation: subHeaderAnimation1 1.5s ease-in-out 0.4s;
          }

          button {
            animation: buttonAnimation 1.5s ease-in-out 0.4s;
          }
        }

        .s__count {
          position: absolute;
          bottom: 18px;
          right: 15%;

          @include portraitTablet {
            right: 5%;
          }

          span {
            font-size: 50px;
            font-weight: 700;
            color: var(--white);

            @include extraLargeTablet {
              font-size: 40px;
            }
          }
        }
      }
    }
  }
}

@keyframes cNameAnimation {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes headerAnimation {
  // 0% {
  //   opacity: 0;
  //   transform: translateX(-150px);
  // }
  // 100% {
  //   opacity: 1;
  //   transform: translateX(0);
  // }

  0% {
    opacity: 0;
    transform: translateY(60px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes subHeaderAnimation1 {
  // 0% {
  //   opacity: 0;
  //   transform: translateX(150px);
  // }
  // 100% {
  //   opacity: 1;
  //   transform: translateX(0);
  // }

  0% {
    opacity: 0;
    transform: translateY(25px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes buttonAnimation {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
