@import "../../../styles/foundations/mixins.scss";

.s {
  // min-height: 400px;
  // width: 100%;
  // display: flex;
  // justify-content: center;
  // align-items: center;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgb(255, 255, 255);

  .sw {
    display: flex;
    margin: 0 auto;
    // height: 100vh;

    .pad {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 4rem;
      font-weight: bold;
      color: rgb(224, 35, 35);
      padding: 0px 25px;
      letter-spacing: 5px;
      margin: 100px auto;

      @include mediumTablet {
        font-size: 4rem;
      }
    }
  }
}

/*spin-X animation*/
.spin-X > span {
  animation: spin-x 3s ease infinite;
}

@keyframes spin-x {
  0%,
  100% {
    transform: rotateX(0deg);
  }
  50% {
    transform: rotateX(180deg) translateY(40px);
  }
}

/*spin-Y animation*/
.spin-Y > span {
  animation: spin-y 3s ease infinite;
}

@keyframes spin-y {
  0%,
  100% {
    transform: rotateY(0deg);
  }
  70% {
    transform: rotateY(180deg) translateX(20px);
  }
}

/*spin-wave animation*/
.spin-wave > span {
  animation: spin-wave 3s ease infinite;
}

@keyframes spin-wave {
  0% {
    transform: rotateX(0deg) translateY(0px);
  }
  50% {
    transform: rotateX(360deg) translateY(45px);
  }
  95%,
  100% {
    transform: rotateX(360deg) translateY(0px);
  }
}

/*scale animation*/
.scale > span {
  animation: scale 3s ease infinite;
}

@keyframes scale {
  0%,
  100% {
    transform: scaleY(1) scaleX(1);
  }
  20% {
    transform: scaleY(2) scaleX(0.75);
  }
  60% {
    transform: scaleY(0.5) scaleX(1.2);
  }
}

.load-letter:nth-child(2) {
  animation-delay: 0.1s;
}

.load-letter:nth-child(3) {
  animation-delay: 0.2s;
}

.load-letter:nth-child(4) {
  animation-delay: 0.3s;
}

.load-letter:nth-child(5) {
  animation-delay: 0.4s;
}

.load-letter:nth-child(6) {
  animation-delay: 0.5s;
}

.load-letter:nth-child(7) {
  animation-delay: 0.6s;
}

.load-letter:nth-child(8) {
  animation-delay: 0.7s;
}

.load-letter:nth-child(9) {
  animation-delay: 0.8s;
}

.load-letter:nth-child(10) {
  animation-delay: 0.9s;
}
