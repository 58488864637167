@import "../../../../styles/foundations/mixins.scss";

.ourPeople {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .ourPeople__container {
    width: 100%;
    max-width: 1550px;
    display: grid;
    // grid-template-columns: 1fr 0.7fr;
    grid-template-columns: 0.7fr 1fr;
    align-items: center;

    gap: 60px;
    padding: 0 80px;

    @media screen and (max-width: 1550px) {
      width: 100%;
      max-width: 100%;
    }

    @include extraLargeTablet {
      grid-template-columns: 1fr;
      order: -1;
    }

    @include tablet {
      gap: 20px;
      padding: 0 40px;
      transition: all 0.3s ease-in-out;
    }

    @include portraitTablet {
      padding: 0 15px;
      transition: all 0.3s ease-in-out;
    }

    .ourPeople__img {
      width: 100%;
      height: 100%;

      @include extraLargeTablet {
        order: 2;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-color: black;
        opacity: var(--imgOpacity);
      }
    }

    .ourPeople__info {
      width: 100%;
      display: flex;
      flex-direction: column;

      gap: 20px;
      padding: 30px 0;
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 1.5px;

      @include extraLargeTablet {
        padding: 0;
      }

      .ourPeople__infoHeaderText {
        width: 100%;
        font-size: 40px;
        font-weight: 700;
        text-transform: uppercase;

        @include miniPortraitTablet {
          font-size: 35px;
        }
      }

      .ourPeople__infoDescText {
        width: 100%;
        font-size: 22px;
        line-height: 30px;
        letter-spacing: 1px;
        font-weight: 500;
        color: var(--textBlack);

        @include miniPortraitTablet {
          width: 90%;
          font-size: 18px;
        }
      }
    }
  }
}
