@import "../../styles/foundations/mixins.scss";

.confirmationPage {
  height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;

  .confirmationPage__container {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    text-align: center;

    h1 {
      font-size: 2.5rem;
      font-weight: 700;
      text-transform: uppercase;

      @include tablet {
        font-size: 30px;
      }
    }

    span {
      font-size: 1.5rem;
      font-weight: 500;
      color: var(--textWhite);

      @include tablet {
        font-size: 20px;
      }
    }
  }
}
