@import "../../../styles/foundations/mixins.scss";

.about {
  height: 100%;
  width: 100%;

  .about__imageDesc {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 0.7fr;
    align-items: center;
    gap: 40px;

    @include extraLargeTablet {
      grid-template-columns: 1fr;
      gap: 30px;
    }

    .about__left {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      gap: 20px;

      .about__descHeader {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .about__descHeaderText {
          font-size: 45px;
          font-weight: 800;
          color: var(--textBlack);
          // text-transform: uppercase;
          letter-spacing: 3px;

          @include extraLargeTablet {
            font-size: 35px;
          }
        }

        .about__descText {
          width: 90%;
          font-size: 35px;
          font-weight: 600;
          color: var(--textBlack);
          letter-spacing: 1px;

          @include extraLargeTablet {
            width: 100%;
            font-size: 25px;
            // text-align: justify;
          }
        }
      }
    }

    .about__image {
      width: 100%;
      display: flex;
      justify-content: center;

      img {
        max-height: 600px;
        height: auto;
        width: 100%;
        object-fit: contain;

        @include extraLargeTablet {
          width: 100%;
        }
      }
    }
  }
}
