@import "../foundations/mixins.scss";

.homePageLayout {
  display: flex;
  flex-direction: column;
  gap: 80px;

  @include portraitTablet {
    gap: 40px;
  }
}

.pageLayout {
  display: flex;
  flex-direction: column;
  padding-bottom: 60px;
}

.pageLayout1 {
  display: flex;
  flex-direction: column;
  padding-bottom: 60px;
  gap: 60px;

  @include portraitTablet {
    gap: 40px;
  }
}

.fullScreenLoading {
  min-height: 100vh;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clipImg {
  clip-path: polygon(45% 0, 100% 18%, 100% 82%, 55% 100%, 0 82%, 0 18%);

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.demoBtns {
  display: flex;
  flex-wrap: wrap;
  row-gap: 15px;
  column-gap: 8px;

  text-decoration: none;
  list-style: none;
  margin: 0px;
  padding: 0px;

  @include portraitTablet {
    // align-items: center;
    // justify-content: center;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .lil {
    text-decoration: none;
    list-style: none;
    float: none;
    // transform: skew(20deg);
    // border: 1px solid rgba(141, 141, 141, 0.664);
    user-select: none;
    cursor: pointer;

    &:hover {
      .liltext {
        color: white !important;
        transition: all 0.3s ease-in-out;
        z-index: 2;

        &::after {
          width: 100%;
          background: var(--brandColor);
          transition: all 0.3s ease 0s;
          z-index: -1;
        }
      }
    }

    .liltext {
      display: block;
      width: 100%;
      height: 100%;
      padding: 6px 10px;
      // border-left: 8px solid transparent;
      position: relative;
      z-index: 2;
      text-decoration: none;
      color: black;
      font-size: 18px;
      letter-spacing: 1px;
      font-weight: 600;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;

      // transform: skew(-20deg);

      @include portraitTablet {
        font-size: 16px;
      }

      &::after {
        content: "";
        height: 100%;
        left: 0;
        top: 0;
        width: 0px;
        position: absolute;
        transition: all 0.3s ease 0s;
        -webkit-transition: all 0.3s ease 0s;
      }
    }
  }

  .lilActive {
    .liltext {
      color: white !important;
      transition: all 0.3s ease-in-out;
      z-index: 2;

      &::after {
        width: 100%;
        background: var(--brandColor);
        transition: all 0.3s ease 0s;
        z-index: -1;
      }
    }
  }
}
