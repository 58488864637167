// imports
@import "./foundations/index.scss";

// font family
@import url("https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Rajdhani", sans-serif;

  // Main Color
  --logoColor: #d7121a;
  --brandColor: #d81419;
  --brandBlack: #1b1b1b;
  --brandWhite: #ffffff;

  --conatantWhite: #ffffff;

  // ------------------------ White Theme ------------------------
  --backgroundColor: #ffffff;
  --contentBackgroundColor: #141414;
  --imgBackgroundColor: #141414;

  --black: #000000;
  --white: #ffffff;

  --textBlack: #000000;
  --textLightGray: #999999;

  --grayBorder: #999999;

  --boxShadow: 0 0 10px rgba(97, 97, 97, 0.2);
  --imgBoxShadow: 10px 10px 10px rgba(97, 97, 97, 0.7);

  --borderSmall: 0.0001px solid rgba(24, 24, 24, 0.212);

  --imgOpacity: 1;
  --imgOpacityBg: 0.3;

  --btnColor: #141414;
  --inputBgColor: #f2f2f2;

  // link text color
  --link-color: #0d6efd;
  --link-hover-color: #0a58ca;
  --code-color: #d63384;

  // Font Size
  --headerText: 45px;
  --headerTextLarge: 45px;
  --headerTextSmall: 35px;

  // gradient colors
  --gray-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );

  // z-index
  --navZindex: 100000000;
}

// ::-webkit-scrollbar-thumb {
//   background-color: rgba(0, 0, 0, 0.5);
//   border-radius: 0.25rem;
// }

// hide scroll bar
::-webkit-scrollbar {
  width: 0;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 180px;
}

// hide number arrows
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
// remove input[type="number"] arrow from firefox
input[type="number"] {
  -moz-appearance: textfield;
}

ul,
li,
a {
  // list-style: none;
  list-style: square;
  text-decoration: none;
  color: inherit;
}

.link {
  text-decoration: none;
}

.app {
  // background-image: url("../assets/images/bg/home-bg.jpg");

  // height: 100vh;
  height: 100%;
  width: 100%;
  background-color: var(--bgColor);
}

.componentPadding {
  padding: 80px 0;

  @include miniPortraitTablet {
    padding: 40px 0;
  }
}

.navlogo {
  width: 190px;
  height: 51.64px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  @include semiLargeDesktop {
    width: 150px;
    height: 40.77px;
    transition: all 0.3s ease-in-out;
  }

  img {
    width: 100%;
    height: 100%;
    transition: all 0.3s ease-in-out;
  }
}

.logoMedium {
  width: 100%;
  display: flex;
  justify-content: center;

  width: 223px;
  height: 30px;
  cursor: pointer;

  @include miniPortraitTablet {
    width: 186px;
    height: 25px;
  }

  img {
    width: 100%;
    height: 100%;
  }
}
