@import "../../../styles/foundations/mixins.scss";

.skeletonServiceListPage {
  display: flex;
  flex-direction: column;
  gap: 40px;

  .sslp__header {
    width: 180px;
    height: 35px;
  }

  .sslp__desc {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .sslp__descText {
      width: 100%;
      height: 15px;
    }
  }

  .sslp__list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;

    @include miniPortraitTablet {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 20px;
      row-gap: 30px;
    }

    .sslp__listCard {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .sslp__listCardImg {
        width: 100%;
        height: 270px;
        border-radius: 10px;

        @include tablet {
          height: 250px;
        }

        @include miniPortraitTablet {
          height: 180px;
        }
      }

      .sslp__listCardText {
        width: 60%;
        height: 30px;
      }
    }
  }
}
