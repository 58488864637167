/*=============================================
=            Responisve                       =
=============================================*/

@mixin mobile {
  @media screen and (max-width: 320px) {
    @content;
  }
}
@mixin mediumMobile {
  @media screen and (max-width: 376px) {
    @content;
  }
}
@mixin largeMobile {
  @media screen and (max-width: 420px) {
    @content;
  }
}
@mixin miniPortraitTablet {
  @media screen and (max-width: 510px) {
    @content;
  }
}
@mixin portraitTablet {
  @media screen and (max-width: 641px) {
    @content;
  }
}
@mixin tablet {
  @media screen and (max-width: 768px) {
    @content;
  }
}
@mixin mediumTablet {
  @media screen and (max-width: 837px) {
    @content;
  }
}
@mixin largeTablet {
  @media screen and (max-width: 879px) {
    @content;
  }
}
@mixin extraLargeTablet {
  @media screen and (max-width: 991px) {
    @content;
  }
}
@mixin smallDesktop {
  @media screen and (max-width: 1024px) {
    @content;
  }
}
@mixin semiLargeDesktop {
  @media screen and (max-width: 1150px) {
    @content;
  }
}
@mixin desktop {
  @media screen and (max-width: 1200px) {
    @content;
  }
}
@mixin mediumDesktop {
  @media screen and (max-width: 1280px) {
    @content;
  }
}
@mixin extraLargeDesktop {
  @media screen and (max-width: 1440px) {
    @content;
  }
}
@mixin smallFourKDevice {
  @media screen and (max-width: 1680px) {
    @content;
  }
}
