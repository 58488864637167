@import "../../../../../styles/foundations/mixins.scss";

.customerPolicy {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .customerPolicy__headerText {
    font-size: 40px;
    font-weight: 700;

    @include largeTablet {
      font-size: 30px;
      transition: all 0.3s ease-in-out;
    }

    @include portraitTablet {
      font-size: 25px;
      transition: all 0.3s ease-in-out;
    }
  }

  .customerPolicy__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .customerPolicy__contentSubHeaderText {
      font-size: 22px;
      font-weight: 700;
    }

    .customerPolicy__contentDescText {
      font-size: 22px;
      font-weight: 500;
    }

    .customerPolicy__contentList {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;

      .customerPolicy__contentListTitle {
        font-size: 22px;
        font-weight: 700;
      }

      .customerPolicy__contentListItems {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-left: 20px;

        .customerPolicy__contentListItem {
          font-size: 22px;
          font-weight: 500;
          list-style: square inside none;
        }
      }
    }
  }
}
