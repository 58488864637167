@import "../../../../styles/foundations/mixins.scss";

.skeletonLandingSvCard {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1b1e24;

  .slsc__title {
    height: 35px;
    width: 50%;
  }
}
