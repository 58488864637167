@import "../../../styles/foundations/mixins.scss";

.projectDetailsPage {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .projectDetails__wrapper {
    position: relative;
    width: 1550px;
    height: 100%;
    padding: 0 80px;

    @media screen and (max-width: 1550px) {
      width: 100%;
    }

    @include tablet {
      padding: 0 40px;
      transition: all 0.3s ease-in-out;
    }

    @include portraitTablet {
      padding: 0 15px;
      transition: all 0.3s ease-in-out;
    }

    .pdw__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 40px;
      margin-top: 200px;
      margin-bottom: 80px;

      @include tablet {
        margin-top: 270px;
        transition: all 0.3s ease-in-out;
      }

      .projectDetails__infos {
        position: absolute;
        top: -170px;
        left: 0;

        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        @include tablet {
          top: -60px;
          transition: all 0.3s ease-in-out;
        }

        .pdi__container {
          width: 90%;
          min-height: 300px;
          display: grid;
          grid-template-columns: 2fr 1fr;
          align-items: center;
          gap: 20px;
          padding: 36px;
          background-color: #0c1018f8;
          border-radius: 5px;

          @include tablet {
            width: 92%;
            padding: 15px;
            grid-template-columns: 1fr;
            transition: all 0.3s ease-in-out;
          }

          .pdi__containerLeft {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .pdicl__item {
              display: grid;
              grid-template-columns: 0.2fr 0.1fr 2fr;
              gap: 10px;

              @include mediumTablet {
                grid-template-columns: 0.1fr 0.1fr 1fr;
                transition: all 0.3s ease-in-out;
              }

              .pdicl__title {
                width: 120px;
                font-size: 24px;
                font-weight: 500;
                color: #cfcfcf;

                @include mediumTablet {
                  font-size: 20px;
                  transition: all 0.3s ease-in-out;
                }

                @include portraitTablet {
                  width: 90px;
                  font-size: 18px;
                  transition: all 0.3s ease-in-out;
                }
              }

              span {
                font-size: 24px;
                font-weight: 500;
                color: #cfcfcf;

                @include mediumTablet {
                  font-size: 18px;
                  transition: all 0.3s ease-in-out;
                }

                @include portraitTablet {
                  font-size: 15px;
                  transition: all 0.3s ease-in-out;
                }
              }
            }
          }

          .pdi__containerRight {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 10px;

            .pdicr__content {
              display: flex;
              flex-direction: column;

              .pdicr__title {
                font-size: 24px;
                font-weight: 500;
                color: #cfcfcf;

                @include mediumTablet {
                  font-size: 18px;
                  transition: all 0.3s ease-in-out;
                }

                @include portraitTablet {
                  font-size: 15px;
                  transition: all 0.3s ease-in-out;
                }
              }

              .pdicr__list {
                display: flex;
                flex-direction: column;

                span {
                  font-size: 24px;
                  font-weight: 500;
                  color: #cfcfcf;

                  @include mediumTablet {
                    font-size: 18px;
                    transition: all 0.3s ease-in-out;
                  }

                  @include portraitTablet {
                    font-size: 15px;
                    transition: all 0.3s ease-in-out;
                  }
                }
              }

              .pdicr__item {
                display: flex;
                align-items: center;
                gap: 10px;

                .pdicr__title1 {
                  width: 100px;
                  font-size: 24px;
                  font-weight: 500;
                  color: #cfcfcf;

                  @include mediumTablet {
                    font-size: 18px;
                    transition: all 0.3s ease-in-out;
                  }

                  @include portraitTablet {
                    width: 90px;
                    font-size: 15px;
                    transition: all 0.3s ease-in-out;
                  }
                }

                span {
                  font-size: 24px;
                  font-weight: 500;
                  color: #cfcfcf;

                  @include mediumTablet {
                    font-size: 18px;
                    transition: all 0.3s ease-in-out;
                  }

                  @include portraitTablet {
                    font-size: 15px;
                    transition: all 0.3s ease-in-out;
                  }
                }
              }
            }
          }
        }
      }

      .projectDetails__gallery {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .pgp__container {
          width: 100%;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 15px;

          @include tablet {
            grid-template-columns: repeat(2, 1fr);
            transition: all 0.3s ease-in-out;
          }

          .pgp__item {
            width: 100%;
            height: 300px;
            display: flex;

            @include tablet {
              height: 200px;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 10px;
              transition: all 0.3s ease-in-out;
            }
          }
        }

        .pgp__modal {
          position: fixed;
          z-index: 1;
          padding-top: 100px;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: auto;
          background-color: rgba(0, 0, 0, 0.9);

          .pgp__modal__content {
            position: relative;
            width: 70%;
            height: 85%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;

            @include tablet {
              width: 90%;
              height: 40%;
            }

            img {
              width: 91%;
              height: 100%;
              object-fit: cover;
              border-radius: 10px;
            }

            .pgp__modal__close {
              position: absolute;
              top: 10px;
              right: 15px;
              font-size: 30px;
              color: white;
              cursor: pointer;

              &:hover {
                color: red;
              }
            }

            .nextImg {
              width: 40px;
              height: 40px;
              // display: flex;
              // align-items: center;
              // justify-content: center;
              // padding: 5px;
              // border: 1px solid white;

              i {
                font-size: 40px;
                color: white;
                cursor: pointer;

                &:hover {
                  color: red;
                }

                @include tablet {
                  font-size: 30px;
                }
              }
            }
          }
        }
      }
    }
  }
}
