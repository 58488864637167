@import "../../../styles/foundations/mixins.scss";

.landingServices {
  width: 100%;
  height: calc(100vh - 200px);

  .landingServices__container {
    position: relative;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    z-index: 10;

    @include extraLargeTablet {
      grid-template-columns: repeat(2, 1fr);
    }

    &:hover {
      .lsc__service {
        background-color: rgb(0, 0, 0);
        opacity: 0.6;
      }

      .ls__btn {
        display: flex;
        z-index: 100000000;
      }
    }

    .lsc__layer {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
      background-color: #1b1e24;
      opacity: 0.8;
      transition: all 0.7s ease;

      @include extraLargeTablet {
        background-color: transparent;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        transition: all 0.7s ease;
      }
    }

    .lsc__service {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      background-color: #1b1e24;
      padding: 0 20px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: rgba(75, 75, 75, 0.507);
        opacity: 0.8;

        h3 {
          color: white;
        }
      }

      @include extraLargeTablet {
        background-color: #1b1e246b;
      }

      h3 {
        font-size: 32px;
        font-weight: 600;
        text-align: center;
        color: white;
        opacity: 1 !important;
        z-index: 10;

        @include portraitTablet {
          font-size: 20px;
        }
      }
    }

    .lsc__service:nth-child(2) {
      border-right: 0.5px solid rgb(121, 121, 121);
      z-index: 10;

      @include extraLargeTablet {
        border-bottom: 0.5px solid rgb(121, 121, 121);
      }
    }
    .lsc__service:nth-child(3) {
      border-right: 0.5px solid rgb(121, 121, 121);
      z-index: 10;

      @include extraLargeTablet {
        border-bottom: 0.5px solid rgb(121, 121, 121);
      }
    }
    .lsc__service:nth-child(4) {
      border-right: 0.5px solid rgb(121, 121, 121);
      z-index: 10;
    }
    .lsc__service:nth-child(5) {
      border-right: 0.5px solid rgb(121, 121, 121);
      border-top: 0.5px solid rgb(121, 121, 121);
      z-index: 10;
    }
    .lsc__service:nth-child(6) {
      border-right: 0.5px solid rgb(121, 121, 121);
      border-top: 0.5px solid rgb(121, 121, 121);
      z-index: 10;
    }
    .lsc__service:nth-child(7) {
      border-right: 0.5px solid rgb(121, 121, 121);
      border-top: 0.5px solid rgb(121, 121, 121);
      z-index: 10;
    }

    .ls__btn {
      position: absolute;
      top: 90%;
      left: 50%;
      transform: translate(-50%, -90%);

      width: 90%;
      display: none;
      align-items: center;
      justify-content: center;
      z-index: 100000000;

      @include extraLargeTablet {
        display: flex;
      }
    }
  }
}
