@import "../../../../styles/foundations/mixins.scss";

// career Page Skeleton
.careerPageSkeleton {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 60px;

  .cps__skeletonContent {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .cps__skeletonHeader {
      height: 40px;
      max-width: 250px;
    }

    .cps__skeletonRowContainer {
      width: 100%;
      display: flex;
      flex-direction: column;

      gap: 10px;
      padding: 10px 0 0 10px;
      box-sizing: border-box;

      .cps__skeletonRowItem {
        width: 100%;
        display: flex;
        justify-content: space-between;

        padding: 16px 0;
        box-sizing: border-box;
        border-bottom: 1px solid #504b4bc5;
        text-decoration: none;
        color: var(--black);
        cursor: pointer;

        @include tablet {
          gap: 30px;
        }
      }
    }
  }
}

// career details page skeleton
.skeleton__careerDetailsPage {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  gap: 60px;

  .scdp__headerBtn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
    border-bottom: 1px solid #8080802f;

    @include portraitTablet {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }

    .scdp__header {
      width: 250px;
      height: 40px;
    }

    .scdp__btn {
      width: 150px;
      height: 40px;
    }
  }

  .scdp__infos {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;

    .scdp__info {
      max-width: 300px;
      height: 28px;
    }
    :nth-child(2) {
      max-width: 400px;
    }
    :nth-child(3) {
      max-width: 350px;
    }
    :nth-child(4) {
      max-width: 250px;
    }
    :nth-child(5) {
      max-width: 200px;
    }
  }

  .scdp__reaquirments {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;

    .scdp__reaquirmentsHeader {
      height: 30px;
      max-width: 300px;
    }

    .scdp__reaquirmentsContainer {
      display: flex;
      flex-direction: column;
      padding: 0 0 0 10px;
      gap: 10px;

      .scdp__rLine {
        width: 90%;
        height: 25px;
      }
      :last-child {
        width: 60%;
      }
    }
  }
}
