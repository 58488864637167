@import "../../../../styles/foundations/mixins.scss";

.aboutPage__missionContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .apv__wrapper {
    width: 100%;
    max-width: 1550px;
    display: grid;
    grid-template-columns: 1fr 0.7fr;
    align-items: center;

    gap: 60px;
    padding: 0 80px;

    @media screen and (max-width: 1550px) {
      width: 100%;
      max-width: 100%;
    }

    @include extraLargeTablet {
      grid-template-columns: 1fr;
    }

    @include tablet {
      gap: 20px;
      padding: 0 40px;
      transition: all 0.3s ease-in-out;
    }

    @include portraitTablet {
      padding: 0 15px;
      transition: all 0.3s ease-in-out;
    }

    .missionContainer__left {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-color: black;
        opacity: var(--imgOpacity);

        @include miniPortraitTablet {
          min-height: 350px;
        }
      }
    }

    .missionContainer__right {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      gap: 20px;
      padding: 30px 0;
      overflow: hidden;

      @include miniPortraitTablet {
        padding: 0;
      }

      h3 {
        font-size: 40px;
        letter-spacing: 1px;
        font-weight: 700;
        color: var(--textBlack);

        @include miniPortraitTablet {
          font-size: 24px;
        }
      }

      span {
        font-size: 22px;
        letter-spacing: 1px;
        font-weight: 600;
        color: var(--textBlack);

        @include miniPortraitTablet {
          font-size: 18px;
        }
      }

      ul {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-left: 20px;

        li {
          list-style: square outside;
          font-size: 22px;
          line-height: 30px;
          letter-spacing: 1px;
          font-weight: 500;
          color: var(--textBlack);

          @include miniPortraitTablet {
            font-size: 18px;
          }
        }
      }
    }
  }
}
