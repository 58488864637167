@import "../../../styles/foundations/mixins.scss";

.headerAnimation {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 0 10px 0;

  .headerAnimation__container {
    display: flex;
    align-items: center;
    gap: 20px;

    @include miniPortraitTablet {
      gap: 15px;
    }

    .headerAnimationLine {
      width: 12px;
      height: 35px;
      background-color: var(--brandColor);
      transform: skew(-25deg);
      margin-left: 10px;

      @include tablet {
        width: 10px;
        height: 30px;
      }

      @include miniPortraitTablet {
        width: 8px;
        height: 25px;
      }
    }

    .headerAnimation__textContainer {
      .headerAnimation__text {
        margin-top: 2px;
        font-size: 50px;
        text-transform: uppercase;
        font-weight: 700;

        @include tablet {
          font-size: 27px;
        }

        @include miniPortraitTablet {
          font-size: 25px;
        }
      }
    }
  }
}
