@import "../../../styles/foundations/mixins.scss";

.c__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;

  h3 {
    font-size: 25px;
    font-weight: 700;
    color: var(--textWhite);
  }

  .cf__inputSection {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .cf__serviceList {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .cf__serviceItem {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 20px;
        column-gap: 50px;
        align-items: center;

        padding: 5px 10px;
        border: 1px solid rgb(187, 187, 187);

        span {
          font-size: 20px;
          font-weight: 500;
          color: black;
        }
      }
    }
  }
}

.cf__inputContainer {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 50px;
  row-gap: 15px;

  @include portraitTablet {
    grid-template-columns: 1fr;
  }

  .cf__btn {
    width: max-content;
    white-space: nowrap;

    padding: 5px 50px;
    border: none;
    background-color: transparent;
    border: 1px solid rgb(187, 187, 187);
    color: black;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: #25283d;
      color: white;
      transition: all 0.3s ease-in-out;
    }
  }
}

.cf__inputContainerFullWidth {
  width: 100%;
}

.cf__inputGroup {
  display: flex;
  flex-direction: column;
  // gap: 3px;

  .cf__label {
    font-size: 19px;
    font-weight: 500;
    color: var(--textWhite);
  }

  .cf__input {
    width: 100%;
    padding: 6px 0;
    border: none;
    background-color: var(--bgColor);
    color: var(--textWhite);
    border-bottom: 2px solid gray;
    font-size: 18px;
    font-weight: 400;
    transition: all 0.3s ease-in-out;

    &:focus {
      outline: none;
      border-bottom: 2px solid rgb(209, 46, 46);
      box-shadow: 0 0 5px 1px var(--textWhite);
    }
  }

  .cf__textarea {
    width: 100%;
    padding: 10px 0;
    border: none;
    background-color: var(--bgColor);
    color: var(--textWhite);
    border-bottom: 2px solid gray;
    font-size: 18px;
    font-weight: 400;
    transition: all 0.3s ease-in-out;
    resize: none;

    &:focus {
      outline: none;
      border-bottom: 3px solid rgb(209, 46, 46);
      box-shadow: 0 0 5px 1px var(--textWhite);
    }
  }
}

.fileInputContainer {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.cfp__fileInput {
  width: max-content;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .cfp__label {
    font-size: 20px;
    font-weight: 600;
    color: var(--textWhite);
  }
}

.cf__check {
  display: flex;
  align-items: center;
  gap: 10px;

  input {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  label {
    font-size: 18px;
    font-weight: 400;
    color: var(--textWhite);
  }
}
