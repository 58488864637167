@import "../../../styles/foundations/mixins.scss";

.skeletonLendingServices {
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @include extraLargeTablet {
    grid-template-columns: repeat(2, 1fr);
  }

  .sls__item {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.2px solid #02020280 !important;

    .sls__itemTitle {
      width: 50%;
      height: 35px;
    }
  }
}
