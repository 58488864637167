@import "../../../styles/foundations/mixins.scss";

.footer {
  width: 100%;
  display: flex;
  background-color: #000000;
  // padding: 80px 0;

  .footerContainer {
    width: 100%;
    display: grid;
    grid-template-columns: 0.5fr 1fr 0.5fr;

    gap: 80px;
    padding: 80px 0;

    @include desktop {
      gap: 40px;
    }

    @include semiLargeDesktop {
      grid-template-columns: 1fr;
    }

    .footerLogo {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-start;

      // background-color: aqua;

      img {
        height: 180px;
        width: 179.49px;

        @include mediumTablet {
          height: 90px;
          width: 90px;
        }
      }
    }

    .footer__infoContainer {
      width: 90%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;

      // background-color: rgb(10, 48, 20);

      .footerItemContainer {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .footerItemTitle {
          font-size: 24px;
          font-weight: 600;
          color: rgb(255, 0, 0);

          @include mediumTablet {
            font-size: 20px;
          }
        }

        .footerItem {
          font-size: 20px;
          flex-wrap: nowrap;
          color: white;
          cursor: pointer;

          @include mediumTablet {
            font-size: 18px;
          }
        }
      }
    }

    .footerCompany {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // align-items: flex-end;
      gap: 20px;

      .footerItemContainer {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .footerItemTitle {
          font-size: 32px;
          font-weight: 600;
          color: rgb(255, 0, 0);
        }

        .footerItem {
          font-size: 20px;
          color: white;
          cursor: pointer;

          @include mediumTablet {
            font-size: 18px;
          }
        }
      }

      .footer__socials {
        display: flex;
        gap: 10px;

        .footer__social {
          width: 35px;
          height: 35px;
          padding: 20px;
          border-radius: 50%;

          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: all 0.3s ease-in-out;

          &:hover {
            background-color: rgb(255, 0, 0);
            transition: all 0.3s ease-in-out;
          }

          svg {
            font-size: 22px;
            color: white;
          }
        }
      }
    }
  }
}
