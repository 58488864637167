@import "../../styles/foundations/mixins.scss";

.contactPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 80px;

  @include portraitTablet {
    gap: 40px;
  }

  .contactContainer {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 0.9fr;
    gap: 60px;

    @include tablet {
      grid-template-columns: 1fr;
    }

    .contsctInfos {
      height: max-content;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      gap: 40px;

      .contactInfo {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .contactInfo__header {
          font-size: 25px;
          font-weight: 600;
          text-transform: uppercase;

          @include miniPortraitTablet {
            font-size: 20px;
          }
        }

        .contactInfo__desc {
          font-size: 20px;
          font-weight: 400;
          color: var(--textWhite);

          @include miniPortraitTablet {
            font-size: 18px;
          }

          a {
            color: black;
            text-decoration: none;
            transition: all 0.3s ease-in-out;
          }
        }
      }
    }

    .companyMap {
      width: 100%;
      min-height: 400px;

      @include extraLargeTablet {
        height: 400px;
      }
    }
  }

  .contactForm__container {
    width: 100%;
    max-width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;

    @include extraLargeTablet {
      max-width: 100%;
    }

    .cf__headerDesc {
      text-align: center;

      span {
        font-size: 20px;
        font-weight: 500;
        color: var(--textWhite);
      }

      h2 {
        font-size: 38px;
        font-weight: 600;
        color: var(--textWhite);
        text-transform: uppercase;

        @include portraitTablet {
          font-size: 30px;
        }
      }
    }

    .cf__btns {
      width: 100%;
      padding: 0 5%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 5px;

      @include portraitTablet {
        padding: 0 1%;
      }

      .cf__btn {
        width: max-content;
        white-space: nowrap;

        padding: 8px 30px;
        border: none;
        background-color: transparent;
        color: var(--textWhite);
        font-size: 20px;
        font-weight: 500;
        text-transform: uppercase;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &:hover {
          background-color: #25283d;
          color: white;
          transition: all 0.3s ease-in-out;
        }

        @include portraitTablet {
          padding: 8px 20px;
          font-size: 18px;
        }

        @include miniPortraitTablet {
          padding: 8px 15px;
          font-size: 16px;
        }
      }

      .active {
        background-color: #25283d;
        color: white;
      }
    }
  }
}
