@import "../../../styles/foundations/mixins.scss";

.primaryBtn {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    .primaryBtn__text {
      color: var(--logoColor) !important;
      transition: all 0.3s ease-in-out;
    }

    .primaryBtn__obstruct {
      .primaryBtn__line {
        animation: primaryBtnLineAnimation 2s ease-in-out infinite;
        background-color: var(--logoColor) !important;
        transition: all 0.3s ease-in-out;
      }

      .primaryBtn__circle {
        border: 2px dotted var(--logoColor) !important;
        transition: all 0.3s ease-in-out;

        .primaryBtn__circle--inner {
          background-color: var(--logoColor) !important;
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }

  .primaryBtn__text {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1.5px;
    color: var(--btnColor);
  }

  .primaryBtn__obstruct {
    position: relative;

    width: 80px;
    height: 50px;
    display: flex;
    align-items: center;
    transition: all 0.3s ease-in-out;

    .primaryBtn__line {
      width: 70%;
      height: 1px;

      background-color: var(--btnColor);
      border-radius: 10px;
      transition: all 0.3s ease-in-out;
    }

    .primaryBtn__circle {
      position: absolute;
      top: 51%;
      left: 70%;
      transform: translate(-50%, -50%);

      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;

      border: 2px dotted var(--btnColor);
      border-radius: 50%;
      transition: all 0.3s ease-in-out;

      .primaryBtn__circle--inner {
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: var(--btnColor);
      }
    }
  }
}

@keyframes primaryBtnLineAnimation {
  0% {
    width: 70%;
  }
  50% {
    width: 0;
  }
  100% {
    width: 70%;
  }
}
