@import "../../../styles/foundations/mixins.scss";

.btn11 {
  //   top: 40px;
  padding: 10px 30px;
  display: inline-block;
  text-decoration: none;
  font-family: "proxima-nova", sans-serif;
  font-weight: 700;
  font-size: 15px;
  text-transform: uppercase !important;
  letter-spacing: 2px;
  color: #fff;
  cursor: hand;
  text-align: center;
  text-transform: capitalize;
  border-radius: 0px;
  //   border: 1px solid transparent;
  position: relative;
  overflow: hidden !important;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background: transparent !important;

  transform: skew(-15deg);
  margin-left: 5px;
}

.btn11:hover {
  //   border: 1px solid transparent;
  color: red !important;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.btn11::before {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background: rgb(49, 49, 49);
  position: absolute;
  left: 7%;
  opacity: 1;
  top: 0;
  z-index: -12;
  -webkit-transition: all 0.65s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: all 0.65s cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: all 0.65s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.65s cubic-bezier(0.77, 0, 0.175, 1);
}

.btn11::after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background: red;
  position: absolute;
  left: -7%;
  opacity: 1;
  top: 0;
  z-index: -15;
  -webkit-transition: all 0.65s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: all 0.65s cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: all 0.65s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.65s cubic-bezier(0.77, 0, 0.175, 1);
}
.btn11:hover::before,
.btn11:hover::before {
  left: -7%;
  opacity: 1;
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
}
.btn11:hover::after,
.btn11:hover::after {
  left: 7%;
  opacity: 1;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}
