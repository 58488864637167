@import "../../../styles/foundations/mixins.scss";

.careerDetailsPage {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 100px;

  .careerDetailsPage__container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    padding-bottom: 80px;

    .cdp__wrapper {
      width: 1550px;
      height: 100%;
      padding: 0 80px;

      @media screen and (max-width: 1550px) {
        width: 100%;
      }

      @include tablet {
        padding: 0 40px;
        transition: all 0.3s ease-in-out;
      }

      @include portraitTablet {
        padding: 0 15px;
        transition: all 0.3s ease-in-out;
      }

      .cdp__content {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 40px;

        .openPositionDetails__headerAndBtn {
          position: sticky;
          top: 99px;

          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: #ffffff;
          border-bottom: 1px solid #b9b9b959;

          z-index: 10000;
          padding: 15px 0;

          @include tablet {
            gap: 20px;
          }

          @include portraitTablet {
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
          }

          .openPositionDetails__header {
            display: flex;
            flex-direction: column;
            gap: 5px;

            span {
              font-size: 25px;
              font-weight: 600;
              // color: var(--white);

              @include tablet {
                font-size: 32px;
                line-height: 32px;
              }

              @include largeMobile {
                font-size: 24px;
                line-height: 24px;
              }
            }
          }

          .openPositionDetails__btn {
            text-decoration: none;
          }
        }

        .openPositionDetails__positionInfoContainer {
          width: 100%;
          display: grid;
          grid-template-columns: 1fr 0.3fr;
          gap: 40px;

          @include portraitTablet {
            grid-template-columns: 1fr;
          }

          .positionInfos {
            display: flex;
            flex-direction: column;
            gap: 5px;

            .positionInfo {
              display: flex;
              gap: 20px;

              @include tablet {
                gap: 10px;
              }

              @include largeMobile {
                gap: 5px;
              }

              .positionInfo__header {
                min-width: 140px;

                span {
                  font-size: 20px;
                  font-weight: 600;
                  line-height: 30px;
                  color: var(--textGoldenSoft);

                  @include miniPortraitTablet {
                    font-size: 16px;
                    line-height: 25px;
                  }
                }
              }

              .positionInfo__content {
                span {
                  font-size: 20px;
                  font-weight: 500;
                  // color: var(--white);
                  line-height: 30px;

                  @include tablet {
                    font-size: 18px;
                  }

                  @include largeMobile {
                    font-size: 16px;
                    line-height: 25px;
                  }
                }
              }
            }
          }

          .applicationDeadline {
            width: max-content;
            display: flex;
            gap: 10px;

            @include portraitTablet {
              order: -1;
            }

            span {
              font-size: 18px;
              font-weight: 600;
              line-height: 30px;
              color: var(--textGoldenSoft);

              @include miniPortraitTablet {
                font-size: 16px;
                line-height: 25px;
              }
            }
          }
        }

        .openPosition__commonHeaderAndContent {
          display: flex;
          flex-direction: column;
          gap: 20px;

          @include tablet {
            gap: 10px;
          }

          .openPosition__commonHeader {
            display: flex;
            flex-direction: column;
            gap: 5px;

            span {
              font-size: 20px;
              font-weight: 600;
              // color: var(--white);

              @include tablet {
                font-size: 18px;
                line-height: 29px;
              }

              @include miniPortraitTablet {
                font-size: 16px;
                line-height: 22px;
              }
            }
          }

          .openPosition__commonContent {
            width: 100%;
            display: flex;
            flex-direction: column;

            ul {
              padding-left: 50px;

              @include miniPortraitTablet {
                padding-left: 30px;
              }

              li {
                font-size: 18px;
                line-height: 25px;
                list-style: square outside;
                // color: var(--white);
                padding-bottom: 10px;

                @include miniPortraitTablet {
                  font-size: 16px;
                  line-height: 22px;
                }
              }
            }

            span {
              font-weight: 400;
              font-size: 18px;
              line-height: 24px;
              // color: var(--white);

              @include miniPortraitTablet {
                font-size: 16px;
                line-height: 22px;
              }
            }
          }
        }

        .prefferContactAndBtnContainer {
          display: flex;
          flex-direction: column;
          gap: 20px;

          .prefferContact {
            width: 100%;
            display: flex;
            padding-bottom: 40px;
            border-bottom: 1px solid #818181;

            span {
              font-weight: 700;
              font-size: 24px;
              line-height: 36px;
            }
          }

          .openPositionBottom__BtnContainer {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            @include tablet {
              flex-direction: column;
              align-items: start;
              gap: 20px;
            }

            .openPositionBottom__BtnContainer__left {
              display: flex;
              align-items: center;
              gap: 20px;

              @include tablet {
                flex-direction: column;
                align-items: start;
                gap: 20px;
              }

              .positionDetails__applyBtn {
                width: 180px;
                height: 60px;
                background-color: #2b2b2b;
                color: #ffffff;
                border: none;
                outline: none;
                cursor: pointer;
                font-weight: 700;
                font-size: 22px;
                line-height: 18px;

                @include tablet {
                  width: 150px;
                  height: 50px;
                  font-size: 18px;
                  line-height: 14px;
                }

                @include largeMobile {
                  width: 120px;
                  height: 40px;
                  font-size: 16px;
                  line-height: 12px;
                }
              }

              .positionDetails__prevApply {
                width: 294px;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
                border: 1px solid #2b2b2b;
                transition: all 0.3s ease-in-out;

                @include tablet {
                  width: 250px;
                  height: 50px;
                }

                @include largeMobile {
                  width: 100%;
                  height: 100%;
                  padding: 8px 20px;
                }

                img {
                  width: 40px;
                  height: 16px;

                  @include largeMobile {
                    width: 30px;
                    height: 12px;
                  }
                }

                span {
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 24px;
                }

                &:hover {
                  border: 1px solid orange;
                  transition: all 0.3s ease-in-out;
                }
              }
            }

            .openPositionBottom__BtnContainer__right {
              display: flex;
              align-items: center;
              gap: 20px;

              @include largeMobile {
                gap: 10px;
              }

              span {
                font-weight: 700;
                font-size: 32px;
                line-height: 32px;

                @include tablet {
                  font-size: 24px;
                  line-height: 24px;
                }

                @include largeMobile {
                  font-size: 18px;
                  line-height: 18px;
                }
              }

              i {
                font-size: 22px;
                line-height: 32px;
                cursor: pointer;

                @include tablet {
                  font-size: 18px;
                  line-height: 24px;
                }

                @include largeMobile {
                  font-size: 16px;
                  line-height: 18px;
                }
              }
            }
          }
        }
      }
    }
  }
}
