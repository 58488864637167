@import "../../../../styles/foundations/mixins.scss";

.apSkeleton__container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .apSkeleton__wrapper {
    width: 100%;

    max-width: 1550px;
    padding: 0 80px;

    @media screen and (max-width: 1550px) {
      width: 100%;
      max-width: 100%;
    }

    @include extraLargeTablet {
      gap: 40px;
      grid-template-columns: 1fr;
    }

    @include tablet {
      // gap: 20px;
      padding: 0 40px;
      transition: all 0.3s ease-in-out;
    }

    @include portraitTablet {
      padding: 0 15px;
      transition: all 0.3s ease-in-out;
    }
  }
}

.apSkeleton__mission {
  width: 100%;
  display: grid;
  grid-template-columns: 0.7fr 1fr;
  align-items: center;
  gap: 60px;

  @include extraLargeTablet {
    gap: 40px;
    grid-template-columns: 1fr;
  }

  .apSkeleton__mImg {
    width: 100%;
    height: 500px;

    @include miniPortraitTablet {
      height: 350px;
    }
  }

  .ap__skeletonContent {
    display: flex;
    flex-direction: column;
    gap: 40px;

    .apSkeleton__mTitle {
      height: 40px;
      width: 50%;
    }

    .apSkeleton__mDesc {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .apSkeleton__mDescText {
        height: 25px;
        width: 100%;
      }

      :last-child {
        width: 75%;
      }
    }

    .apSkeleton__mPoints {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .apSkeleton__mPointsText {
        height: 25px;
        width: 80%;
      }
    }
  }
}

.apSkeleton__vission {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 0.7fr;
  align-items: center;
  gap: 60px;

  @include extraLargeTablet {
    gap: 40px;
    grid-template-columns: 1fr;
  }

  .apSkeleton__mImg {
    width: 100%;
    height: 500px;

    @include miniPortraitTablet {
      height: 350px;
    }
  }

  .ap__skeletonContent {
    display: flex;
    flex-direction: column;
    gap: 40px;

    .apSkeleton__mTitle {
      height: 40px;
      width: 50%;
    }

    .apSkeleton__mDesc {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .apSkeleton__mDescText {
        height: 25px;
        width: 100%;
      }

      :last-child {
        width: 75%;
      }
    }

    .apSkeleton__mPoints {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .apSkeleton__mPointsText {
        height: 25px;
        width: 80%;
      }
    }
  }
}

.apSkeleton__policy {
  display: flex;
  flex-direction: column;
  gap: 20px;

  padding: 80px 0;

  .apSkeleton__policyHeader {
    height: 35px;
    width: 100%;
    max-width: 100px;
  }

  .app__skeletonContent {
    display: flex;
    flex-direction: column;

    .apSkeleton__policyBtn {
      height: 80px;
      width: 100%;
      max-width: 180px;
    }

    .apSkeleton__policyContent {
      display: flex;
      flex-direction: column;

      padding: 50px 50px;

      background-color: rgb(124, 124, 124);

      @include portraitTablet {
        padding: 30px 20px;
      }
    }
  }
}

.app__skeletonColorbg {
  background-color: #1b1e24;
}

.apSkeleton__mDesc {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .apSkeleton__mDescText {
    height: 25px;
    width: 100%;
  }

  :last-child {
    width: 75%;
  }
}

.apSkeleton__mPoints {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .apSkeleton__mPointsText {
    height: 25px;
    width: 80%;
  }
}
