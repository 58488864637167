@import "../../../styles/foundations/mixins.scss";

.serviceListPage {
  display: flex;
  flex-direction: column;

  .serviceListPage__container {
    display: flex;
    flex-direction: column;
    gap: 40px;

    .serviceListPage__header {
      display: flex;
      flex-direction: column;
      gap: 5px;

      h3 {
        font-size: 30px;
        font-weight: 600;
        letter-spacing: 1.5px;
        color: var(--black);

        @include tablet {
          font-size: 24px;
        }

        @include miniPortraitTablet {
          font-size: 22px;
        }
      }
    }

    .servicePage__desc {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;

      p {
        font-size: 19px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 1px;
        text-align: justify;
        color: var(--black);

        @include tablet {
          font-size: 18px;
        }
      }
    }

    .servicesList {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      gap: 20px;

      @include miniPortraitTablet {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 20px;
        row-gap: 30px;
      }

      .servicesItem {
        display: flex;
        flex-direction: column;
        gap: 3px;

        img {
          width: 100%;
          height: 280px;
          object-fit: cover;
          background-color: black;
          opacity: var(--imgOpacity);
          border-radius: 10px;

          @include tablet {
            height: 250px;
          }

          @include miniPortraitTablet {
            height: 180px;
          }
        }

        .servicesItemInfo {
          display: flex;
          flex-direction: column;
          padding-left: 10px;

          span {
            padding-top: 5px;
            font-size: 18px;
            font-weight: 600;
            letter-spacing: 1.5px;
            color: var(--black);

            @include miniPortraitTablet {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
