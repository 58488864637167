.repeartAnimation {
  position: relative;
  width: 50px;
  height: 1px;
  display: flex;
  overflow: hidden;
  background-color: var(--white);

  .repeatLine1 {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    background-color: var(--brandColor);
    animation: lineAnimation1 3s linear infinite;
  }

  .repeatLine2 {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    padding-left: 10%;
    border-radius: 2px;
    background-color: var(--brandColor);
    animation: lineAnimation2 3s linear infinite;
    animation-delay: 4.5s;
  }
}

@keyframes lineAnimation1 {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(120%);
  }
}

@keyframes lineAnimation2 {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(120%);
  }
}
