@import "../../../../styles/foundations/mixins.scss";

.stickyBtnContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px;

  padding: 22px 15px;
  margin-bottom: 40px;
  border-bottom: 1.5px solid rgba(209, 209, 209, 0.541);
  background-color: white;
  z-index: 10000;
}
